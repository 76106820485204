<template>
  <div>
        <v-row>
      <v-col>
        <v-toolbar flat dense>
          <div>
            {{ group.name }}
          </div>

          <v-spacer></v-spacer>

          <v-menu v-model="editTitle" :close-on-content-click="false" width="600" offset-y>
            <template #activator="{ attrs, on }">
              <v-btn v-bind="attrs" v-on="on" text small>
                Ret navn
                <v-icon right> mdi-pencil-outline </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-text>
                <v-text-field v-model="titleInput" />
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="editTitle = false">
                  Annuller
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn :loading="saving" @click="update">
                  Gem
                  <v-icon right> mdi-content-save-outline </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <!-- <v-menu
            offset-y
          >
            <template #activator="{ attrs, on }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                small
              >
                Slet sektion
                <v-icon right> mdi-delete-outline </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                Er du sikker?
              </v-card-title>
              <v-card-text>
                Materialer og arbejder under denne sektion vil også blive slettet.
              </v-card-text>
              <v-card-actions>
                <v-btn @click="deleteSection">
                  Ja, slet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu> -->

          <!-- <v-btn
            :loading="loadingMessages"
            @click="showRightDrawer = true"
            text
            small
          >
            <v-icon small>
              mdi-message-outline
            </v-icon>
          </v-btn> -->
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Total kostpris
          </v-card-title>

          <v-card-text>
            DKK {{ totalCost | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Total salgspris
          </v-card-title>

          <v-card-text>
            DKK {{ totalPrice | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Totalt materialer (kostpris)
          </v-card-title>

          <v-card-text>
            DKK {{ totalParts | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <v-card>
          <v-card-title>
            Total arbejdstimer
          </v-card-title>

          <v-card-text>
            {{ totalHours | formatNumberFixed2 }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SpecificationMaterialTable :items="aggregatedParts" :locked="$attrs.locked" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <SpecificationLaborTable :items="aggregatedLabors" :locked="$attrs.locked" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpecificationMaterialTable from '@/components/specification/SpecificationMaterialTable.vue';
import SpecificationLaborTable from '@/components/specification/SpecificationLaborTable.vue';
import ApprovalStatus from '@/components/approval/ApprovalStatus.vue';
import ActivityLog from '@/components/ActivityLog.vue';

export default {
  name: 'SpecificationGroup',
  components: {
    SpecificationMaterialTable,
    SpecificationLaborTable,
    ApprovalStatus,
    ActivityLog
  },
  props: {
    approvals: {
      type: Array,
      required: true
    },
    spec: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      deleteMenu: false,
      deleting: false,
      editTitle: false,
      locking: false,
      saving: false,
      showRightDrawer: false,
      titleInput: ''
    }
  },
  computed: {
    group() {
      return this.spec.groups.find(x => x.number === Number(this.$route.params.groupNumber));
    },
    sections() {
      return this.group.sections;
    },
    totalCost() {
      return this.sections.reduce((prev, current) => {
        const parts = current.parts.reduce((a, b) => a + b.costPrice * b.totalQuantity, 0);
        const labors = current.labors.reduce((a, b) => a + b.hourCostPrice * b.totalHours, 0);

        return prev + (parts + labors);
      }, 0);
    },
    totalPrice() {
      return this.sections.reduce((prev, current) => {
        const parts = current.parts.reduce((a, b) => a + b.price * b.totalQuantity, 0);
        const labors = current.labors.reduce((a, b) => a + b.hourPrice * b.totalHours, 0);

        return prev + (parts + labors);
      }, 0);
    },
    totalParts() {
      return this.sections.reduce((prev, current) => {
        return prev + current.parts.reduce((a, b) => a + b.costPrice * b.totalQuantity, 0);
      }, 0);
    },
    totalHours() {
      return this.sections.reduce((prev, current) => {
        return prev + current.labors.reduce((a, b) => a + b.totalHours, 0);
      }, 0);
    },
    aggregatedParts() {
      let allParts = this.sections.map(x => x.parts).flat();
      const result = [];

      allParts.forEach(part => {
        if (result.some(x => x.part_Id == part.part_Id)) return;

        result.push({
          ...part,
          quantity: allParts.filter(x => x.part_Id == part.part_Id).reduce((a, b) => a + b.totalQuantity, 0)
        });
      });

      return result;
    },
    aggregatedLabors() {
      let allLabors = this.sections.map(x => x.labors).flat();
      const result = [];

      allLabors.forEach(labor => {
        if (result.some(x => x.customerRole_Id == labor.customerRole_Id)) return;

        result.push({
          ...labor,
          hours: allLabors.filter(x => x.customerRole_Id == labor.customerRole_Id).reduce((a, b) => a + b.totalHours, 0)
        });
      });

      return result;
    }
  },
  methods: {
    update: async function() {
      this.saving = true;

      const data = {
        name: this.titleInput
      }

      try {
        const response = await this.$AuthService.api.patch(`specification/section-group/${this.group.id}`, data);

        if (!response.data.success) throw 'Error updating group';

        this.section = response.data.model;

        this.$notifier.showSuccess({ message: 'Ændringer blev gemt' });
        this.editTitle = false;
        this.$emit('group:edit');
      } catch (error) {
        console.error(error);
        this.$notifier.showError({}, 'Der skete en fejl. Ændringerne blev ikke gemt.');
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>