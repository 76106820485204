<template>
  <div>
    <template v-if="!sentOn">
      <v-chip>
        Kladde
        <v-icon class="ml-1" small> mdi-file-question-outline </v-icon>
      </v-chip>
    </template>
    <template v-else-if="respondedOn">
      <v-chip v-if="approved" color="success">
        Godkendt
        <v-icon class="ml-1" small> mdi-thumb-up-outline </v-icon>
      </v-chip>
      <v-chip v-if="!approved" color="error">
        Afvist
        <v-icon class="ml-1" small> mdi-thumb-down-outline </v-icon>
      </v-chip>
      <div class="mt-1 text-caption">
        {{ respondedOn | formatDateTime }}
      </div>
    </template>
    <template v-else>
      <v-chip v-if="sentOn && !respondedOn" color="warning">
        Afventer
        <v-icon class="ml-1" small> mdi-comment-question-outline </v-icon>
      </v-chip>
    </template>
    <div v-if="referenceInformation" class="reference-information mt-1">
      <span> {{ referenceInformation.label }}: </span>
      <span>
        {{ referenceInformation.value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApprovalStatus',
  props: [ 'sentOn', 'respondedOn', 'approved', 'referenceInformation' ]
}
</script>